<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "일별 안전점검 결과 목록"
            tableId="facilitySelfCheck"
            gridHeight="750px"
            :columns="grid.columns"
            :data="grid.data"
            :editable="popupParam.data.checkStatusCd !== 'MCSC000015'"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
          >
            <template slot="suffixTitle">
              <font v-if="!popupParam.data.plantCd || !popupParam.data.checkDate" color="#C10015">
                ※ 사업장 및 점검년월을 선택하세요.
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
                <q-chip
                  v-for="(item, index) in $comm.inspectIbmTagItems(props.row, colorItems, popupParam.col.key)"
                  :key="index"
                  :color="item.ibmClassCd === 'IC00000001' ? 'red-6' : 'light-blue'"
                  :clickable="true"
                  text-color="white"
                  style="margin-bottom:4px !important;"
                  @click="linkClick(item, props.row)">
                  {{item.title}}
                  <c-assess-tooltip 
                    :ibmTooltip="item.ibmTooltip"
                    :ibmClassCd="item.ibmClassCd"
                  />
                </q-chip>
              </template>
              <div v-if="popupParam.data.safetyCheckId && !disabled && col.name === 'customCol'">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    v-if="editable && !disabled"
                    icon="add"
                    color="red-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props, '1')">
                    <q-tooltip>
                      개선요청등록
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    v-if="editable && !disabled"
                    icon="add"
                    color="light-blue"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props, '2')">
                    <q-tooltip>
                      즉시조치등록
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
              <template v-else-if="col.name === popupParam.col.key">
                <c-select
                  stype="tableselect"
                  :editable="editable"
                  :comboItems="checkboxItems"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  label=""
                  name="checkItems"
                  v-model="props.row[col.name]"
                  @datachange="datachange(props)"
                >
                </c-select>
              </template>
            </template>
          <template slot="table-header-append">
            <c-field
              :editable="editable"
              style="min-width: 150px;max-width: 250px;padding-bottom: 0px !important"
              name="checkUserId"
              placeholder="점검자 선택"
              v-model="checkUserId">
            </c-field>
          </template>
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="popupParam.data.safetyCheckId ? 
                  { 
                    safetyCheckResultModelList: popupParam.data.safetyCheckResultModelList,
                    safetyCheckUserModel: {
                      dayId: this.popupParam.col.key,
                      safetyCheckId: this.popupParam.data.safetyCheckId, 
                      checkUserId: this.checkUserId
                    },
                  } : this.popupParam.data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-checking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        col: {},
        equipmentList: [],
        data: {
          safetyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkDate: '',  // 점검월
          deteriorName: '',  // 점검명
          safetyCheckResultModelList: [], // sheet
          safetyCheckUserModelList: [],
        }
      }),
    },
    returnData: {
      type: Object,
      default: () => ({
        col1: null,
        col2: null,
        col3: null,
      }),
    }
  },
  data() {
    return {
      year: '',
      grid: {
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: false,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      checkUserId: '',
      checkboxItems: [],
      colorItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.popupParam.data.checkStatusCd === 'MCSC000015' || Boolean(this.popupParam.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting

      this.getUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.insertUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      this.resultItemDetail = selectConfig.sop.safetycheck.result.newItem.url;
      this.updateUrl = transactionConfig.sop.safetycheck.result.newItem.url;
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      if (this.$_.findIndex(this.popupParam.data.safetyCheckUserModelList, {dayId: this.popupParam.col.key}) !== -1) {
        let idx = this.$_.findIndex(this.popupParam.data.safetyCheckUserModelList, {dayId: this.popupParam.col.key});
        this.checkUserId = this.popupParam.data.safetyCheckUserModelList[idx].checkUserId;
      }

      this.grid.data = this.popupParam.data.safetyCheckResultModelList;
      this.$comm.getComboItems('MIN_SAFETY_RESULT_CD').then(_result => {
        this.checkboxItems = _result;
        let _columns = [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'checkItemCycleName',
            field: 'checkItemCycleName',
            label: '주기',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ]
        let days = [];
        days.push(
          {
            name: this.popupParam.col.key,
            field: this.popupParam.col.key,
            label: this.popupParam.col.day + '일(' + this.popupParam.col.label + ')',
            align: 'center',
            setHeader: true,
            comboItems: _result,
            style: 'width:100px',
            type: 'custom',
            headType: 'select',
            sortable: false,
          // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
          },
        )
        this.grid.columns = this.$_.concat(_columns, days, 
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:350px',
                type: 'custom',
                sortable: false
              },
            ]
          },)
      });
    },
    research() {
      this.$http.url = this.$format(this.getUrl, this.popupParam.data.safetyCheckId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data.safetyCheckResultModelList;
      },);
    },
    saveData() {
      if (this.popupParam.data.safetyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.popupParam.data.regUserId = this.$store.getters.user.userId;
              this.popupParam.data.chgUserId = this.$store.getters.user.userId;
              
              this.popupParam.data.safetyCheckResultModelList = this.$_.clone(this.grid.data);

              if (this.mappingType === 'POST') {
                this.popupParam.data.safetyCheckUserModelList.push({
                  dayId: this.popupParam.col.key,
                  safetyCheckId: this.popupParam.data.safetyCheckId, 
                  checkUserId: this.checkUserId,
                  editFlag: 'C',
                })
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.popupParam.data.safetyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.safetyCheckId = result.data;
          _item.editFlag = 'U';
        })
        this.research();
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.popupParam.col.key,
          ibmTaskTypeCd: 'ITT0000075'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.width = '99%';
        this.popupOptions.iFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.popupParam.col.key,
          ibmTaskTypeCd: 'ITT0000075'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
        this.popupOptions.width = '99%';
        this.popupOptions.iFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.popupParam.data.safetyCheckResultModelList = this.$_.clone(this.grid.data);

        this.$http.url = transactionConfig.sop.safetycheck.result.newItem.url;
        this.$http.type = 'PUT';
        this.$http.param = {
          safetyCheckResultModelList: this.$_.clone(this.grid.data),
          safetyCheckUserModel: {
            dayId: this.popupParam.col.key,
            safetyCheckId: this.popupParam.data.safetyCheckId, 
            checkUserId: this.checkUserId
          },
        }
        this.$http.request(() => {
          this.research();
          this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        },);
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '99%';
        this.popupOptions.iFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '99%';
        this.popupOptions.iFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.$http.url = transactionConfig.sop.safetycheck.result.newItem.url;
      this.$http.type = 'PUT';
      this.$http.param = {
        safetyCheckResultModelList: this.$_.clone(this.grid.data),
        safetyCheckUserModel: {
          dayId: this.popupParam.col.key,
          safetyCheckId: this.popupParam.data.safetyCheckId, 
          checkUserId: this.checkUserId
        },
      }
      this.$http.request(() => {
        this.research();
      },);
    },
  }
};
</script>

<style lang="sass">
.customDate
  padding-bottom: 0px !important
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important
.custom-field
  .q-field__native
    cursor: pointer
.q-field--dense .q-field__inner
  padding-bottom: 0px !important
  </style>
